import React, { FC, Fragment, useEffect, useRef, useState } from "react";
import PageHeader from "../../../../shared/components/PageHeader";
import "./customerProfile.scss";
import { Button, Col, Form, Row, Tabs } from "antd";
import BankIcon from "./../../../../assets/icons/multi color icons - svg/bank details.svg";
import EmergencyIcon from "./../../../../assets/icons/multi color icons - svg/emergency contact.svg";
import BeneficiaryIcon from "./../../../../assets/icons/multi color icons - svg/beneficary.svg";
import PlayButton from "./../../../../assets/icons/single color icons - svg/play.svg";
import DocumentIcon from "./../../../../assets/icons/multi color icons - svg/documents.svg";
import CustomerService from "../../../../services/CustomerService/customer.service";
import { useHistory } from "react-router-dom";
import {
  Customer,
  DocumentVerificationModel,
  KYCBasicInfo,
} from "../../../../models/Customer/customer.model";
import DocumentViewer from "../../../../shared/components/DocumentViewer";
import RiskAssessmentQuestion from "../../../../shared/components/RiskAssessmentQuestion";
import ProfileBasicDetailsCard from "../../../../shared/components/ProfileBasicDetailsCard";
import ProfileOtherDetailsCard from "../../../../shared/components/ProfileOtherDetailsCard";
import KycDocumentModal from "../../../../shared/components/KycDocumentModal";
import * as AppRoutes from "../../../../routes/routeConstants/appRoutes";
import { Formik, FormikProps } from "formik";
import AlertModal from "../../../../shared/components/AlertModal";
import { WarningFilled } from "@ant-design/icons";
import InputField from "../../../../shared/components/InputField";
import { REGEX } from "../../../../shared/utils/regex";
import * as yup from "yup";
import CustomerDetails from "../../../../shared/components/CustomerDetails";
import AuthContainer from "../../../../store/container/AuthContainer";
import { AuthState } from "../../../../store/reducers/authReducer";
import { User } from "../../../../models/user.model";
import { CustomerTypes } from "../../../../enums/customerTypes";
import RadioField from "../../../../shared/components/RadioField";
import { CustomerType, CustomerTypeIDType } from "../../../../models/CustomerType/customerType.model";
import CustomerWallet from "./CustomerWallet";
import CustomerHoldings from "./CustomerHoldings";
import { custom } from "serializr";
import SubscriptionModal from "../../../../shared/components/SubscriptionModal";
import UploadComponent from "../../../../shared/components/UploadComponent";
import { customerRoles } from "../../Report/report.data";

const KenyanNationNumberValidationSchema = yup.object().shape({
  kenyanNationalNumber: yup
    .string().ensure().when('idType', {
      is: (idType?: CustomerTypeIDType) => idType === "IDCARD",
      then: yup.string().required("Kenyan National Number is required!")
        .min(1, "Kenyan National Number must be 1 characters")
        .max(9, "Kenyan National Number should not be more than 9 characters")
        .matches(REGEX.NUMERIC, "Kenyan National Number should be numeric"),
      otherwise: yup.string()
        .max(9, "ID Number should not be more than 9 characters")
        .required("ID Number is required!")
    }),
});

interface CustomerProfileProps extends AuthState {
  // isAll: boolean
}

class SelectedDocType {
  url: string = "";
  name: string = "";
  type: string = "";
  title: string = "";
  status?: string = "";
  visible: boolean = false;
}

const CustomerProfile: FC<CustomerProfileProps> = (props) => {
  const { TabPane } = Tabs;
  const history = useHistory();
  const customerId = history.location.pathname.split("/").pop();
  const state = history.location.state as any;

  const [enableVerification, setEnableVerification] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<string>("1");

  const { user } = props;

  const {
    loading,
    customer,
    approving,
    kycDetails,
    verifyKycDocuments,
    updateNationalNumber,
    syncCustomerLookupData,
    fetchSingleCustomerKycDetails,
    fetchSingleCustomerBasicDetails,
  } = CustomerService();

  const [selectedDoc, setSelectedDoc] = useState<SelectedDocType>(
    new SelectedDocType()
  );
  const [verificationData, setVerificationData] =
    useState<DocumentVerificationModel>(new DocumentVerificationModel());
  const [changeDetails, setChangeDetails] = useState(false);
  const [subscriptionModal, setSubscriptionModal] = useState(false);
  const [reviewDetails, setReviewDetails] = useState<{
    customer: KYCBasicInfo;
    url: string;
  }>();
  const formikRef = useRef<any>(null);

  const initialValue = Object.assign(new CustomerType(), { idType: "IDCARD" });

  useEffect(() => {
    if (customerId ) {
      fetchData(customerId);
    } else {
      history.push(AppRoutes.CUSTOMERS);
    }
  }, [user?.role]);

  useEffect(() => {
    if (state && state?.enableVerification) {
      setEnableVerification(state?.enableVerification);
    }
  }, [state]);

  

  const fetchData = (customerId: string) => {
    fetchSingleCustomerBasicDetails(customerId);
    fetchSingleCustomerKycDetails(customerId);
  };

  const updateChangeDetails = (view: boolean) => setChangeDetails(view);

  const handleConfirm = () => {
    formikRef.current?.handleSubmit();
  };

  const disableActionEvents: boolean = user?.role===CustomerTypes.DIGITAL;

  const handleSyncDetails = async () => {
    try {
      // await syncCustomerLookupData(reviewDetails?.url || "", customer?.id);
      setReviewDetails(undefined);
      if (customerId && user?.role === CustomerTypes.ADMIN) {
        fetchData(customerId);
      } else {
        history.push(AppRoutes.CUSTOMERS);
      }
    } catch (error) { }
  };

  const beneficiaryDetails = {
    title: "Beneficiary Details",
    icon: BeneficiaryIcon,
    iconBgColor: "#5c4e791a",
    contents: [
      {
        label: "Name",
        value: customer?.beneficiaryDetail?.name,
      },
      {
        label: "Kenyan National ID",
        value: customer?.beneficiaryDetail?.kenyanNationalNumber,
      },
      {
        label: "Phone Number",
        value: customer?.beneficiaryDetail?.phone,
      },
      {
        label: "Relation",
        value: customer?.beneficiaryDetail?.relation,
      },
    ],
  };

  const emergencyDetails = {
    title: "Emergency Contact",
    icon: EmergencyIcon,
    iconBgColor: "#a4d0581a",
    contents: [
      {
        label: "Name",
        value: customer?.emergencyContact?.name,
      },
      {
        label: "Phone Number",
        value: customer?.emergencyContact?.phone,
      },
    ],
  };

  const bankDetails = {
    title: "Bank Details",
    icon: BankIcon,
    iconBgColor: "#ffa31a52",
    contents: [
      {
        label: "Bank",
        value: customer?.kycBankDetails?.bankName,
      },
      {
        label: "Account Number",
        value: customer?.kycBankDetails?.accountNumber,
      },
      {
        label: "IFSC",
        value: customer?.kycBankDetails?.branchName,
      },
    ],
  };

  const tabChangeHandler = (key: string) => {
    setSelectedTab(key)
  };

  const documentModalCloseHandler = () => {
    setSelectedDoc((prev) => {
      const newData = { ...prev };
      newData.visible = false;
      return newData;
    });
  };

  useEffect(() => {
    verificationData.video = statusUpdater(
      "video",
      kycDetails?.kycVideoProof?.verifiedBy,
      kycDetails?.kycVideoProof?.rejectionReason,
      true
    );
    verificationData.photograph = statusUpdater(
      "photograph",
      kycDetails?.kycIdProof?.photographVerifiedBy,
      kycDetails?.kycIdProof?.kenyanIdProofRejectionReason,
      true
    );
    verificationData.kraPin = statusUpdater(
      "kraPin",
      kycDetails?.kycIdProof?.kraPinVerifiedBy,
      kycDetails?.kycIdProof?.kraPinRejectionReason,
      true
    );
    verificationData.kenyanNationalId = statusUpdater(
      "kenyanNationalId",
      kycDetails?.kycIdProof?.kenyanIdProofVerifiedBy,
      kycDetails?.kycIdProof?.kenyanIdProofRejectionReason,
      true
    );

    // console.log(verificationData);
  }, [kycDetails]);

  useEffect(() => {
    // console.log(verificationData);
    setSelectedDoc((prev) => {
      const updatedVerificationData: any = { ...verificationData };
      if (updatedVerificationData[selectedDoc.title] !== undefined) {
        prev.status =
          updatedVerificationData[selectedDoc.title] === false
            ? "accepted"
            : updatedVerificationData[selectedDoc.title];
      }
      return prev;
    });
  }, [verificationData]);

  const statusUpdateHandler = (field: string, value: false | string) => {
    setVerificationData((prev) => {
      const newData: any = { ...prev };
      newData[field] = value;
      return newData;
    });
    setSelectedDoc((prev) => {
      const newData = { ...prev };
      newData.status =
        value === undefined
          ? "pending"
          : value === false
            ? "accepted"
            : "rejected - " + value;
      return newData;
    });
    documentModalCloseHandler();
  };

  const saveChangesHandler = async () => {
    if (!customer?.id) {
      return;
    }
    const resultantObject: any = { ...verificationData };
    for (const key in resultantObject) {
      if (Object.prototype.hasOwnProperty.call(resultantObject, key)) {
        const element = resultantObject[key];
        if (!element) {
          resultantObject[key] = undefined;
        }
      }
    }
    try {
      await verifyKycDocuments(customer?.id, resultantObject);
      history.push(AppRoutes.CUSTOMERS);
    } catch (error) { }
  };

  const handleSubmit = async (values: CustomerType) => {
    if (!values.kenyanNationalNumber || !values.idType) {
      return;
    }
    try {
      const customerData = await updateNationalNumber({
        id: values.kenyanNationalNumber,
        type: values.idType,
      });
      if (!!customerData?.customer) {
        formikRef.current?.handleReset();
        setChangeDetails(false);
        customerData.customer.idNumber = values.kenyanNationalNumber
        customerData.customer.idType = values.idType
        customerData.customer.isFromIprs = true
        customerData.customer.userId = customer?.id
        setReviewDetails({
          ...customerData,
        });
      }
    } catch (error) { }
  };

  const statusUpdater = (
    key: "video" | "photograph" | "kenyanNationalId" | "kraPin",
    verifiedBy?: string,
    rejectionReason?: string,
    isInitial?: boolean
  ) => {
    let resultantStatus: string;
    if (verificationData[key] === undefined) {
      if (verifiedBy) {
        if (rejectionReason) {
          resultantStatus = "pending";
          setVerificationData((prev) => {
            const newData = { ...prev };
            newData[key] = undefined;
            return newData;
          });
          // resultantStatus = 'rejected - ' + kycDetails?.kycVideoProof?.rejectionReason
          // verificationData[key] = 'rejected - ' + kycDetails?.kycVideoProof?.rejectionReason
        } else {
          resultantStatus = "accepted";
          setVerificationData((prev) => {
            const newData = { ...prev };
            newData[key] = false;
            return newData;
          });
        }
      } else {
        resultantStatus = "pending";
      }
    } else {
      if (verificationData[key] === false) {
        resultantStatus = "accepted";
      } else {
        resultantStatus = "rejected - " + verificationData[key];
      }
    }
    if (isInitial) {
      return undefined;
    } else {
      return resultantStatus;
    }
  };
  const openSubsciptionModal = () => setSubscriptionModal(true)

  const closeSubsciptionModal = (response?: boolean) => {
    setSubscriptionModal(false)
    response && customerId && fetchData(customerId)
  }

  const ChangeDetailsTemplate = (
    <div className="change-kenyan-id__container mt-5">
      <Formik
        innerRef={formikRef}
        initialValues={initialValue}
        onSubmit={handleSubmit}
        validationSchema={KenyanNationNumberValidationSchema}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <RadioField
              name="idType"
              title="Proof Type"
              value={values.idType}
              options={[{ label: 'Kenyan National ID', value: "IDCARD" }, { label: "Alien Card ID", value: "ALIENCARD" }, { label: "Passport ID", value: "PASSPORT" }]}
              setFieldValue={(field: string, value: string) => {
                setFieldValue("idType", value);
              }}
            />
            <InputField
              type="text"
              title="Kenyan National ID / Alien card number / Passport"
              name="kenyanNationalNumber"
              placeholder="Enter here"
            />
          </Form>
        )}
      </Formik>
    </div>
  );

  const tabContentRenderer = (customer: Customer) => {
    if (!customer?.name) {
      return;
    }
    return (
      <Fragment>
        <Row className={`customer-profile__body-basic-details ${disableActionEvents && 'pointer-none'}`}>
          <Col lg={12} sm={24} xs={24}>
            <ProfileBasicDetailsCard
              openModal={setChangeDetails}
              customer={customer}
            />
            <div className="kyc-video__container">
              <div className="kyc-video--mask"></div>
              <video
                src={kycDetails?.kycVideoProof?.verificationVideo}
                playsInline
                onClick={() => {
                  setSelectedDoc({
                    url: kycDetails?.kycVideoProof?.verificationVideo || "",
                    type: "video",
                    name: "Video KYC",
                    title: "video",
                    visible: true,
                    status: statusUpdater(
                      "video",
                      kycDetails?.kycVideoProof?.verifiedBy,
                      kycDetails?.kycVideoProof?.rejectionReason
                    ),
                  });
                }}
              />
              {customerId && <UploadComponent
                refreshData={() => customerId && fetchData(customerId)}
                customerId={+customerId}
                accept="video"
                keyName={"video"} />}
              <img
                className="kyc-video--play-btn"
                src={PlayButton}
                alt="play"
                onClick={() => {
                  setSelectedDoc({
                    url: kycDetails?.kycVideoProof?.verificationVideo || "",
                    type: "video",
                    name: "Video KYC",
                    title: "video",
                    visible: true,
                    status: statusUpdater(
                      "video",
                      kycDetails?.kycVideoProof?.verifiedBy,
                      kycDetails?.kycVideoProof?.rejectionReason
                    ),
                  });
                }}
              />
              {enableVerification && (
                <p
                  className={`kyc--status ${verificationData.video === undefined
                    ? "pending"
                    : verificationData.video === false
                      ? "accepted"
                      : "rejected"
                    }`}
                >
                  {verificationData.video === undefined
                    ? "pending"
                    : verificationData.video === false
                      ? "accepted"
                      : "rejected"}
                </p>
              )}
            </div>
          </Col>
          <Col lg={12} sm={24} xs={24}>
            <div className="subscription-details">
              <p className="subscription">
                {!customer?.activeSubscription?.name
                  ? "Not Subscribed"
                  : customer?.activeSubscription?.name + " Subscription"}
              </p>
              {customer.activeSubscription&&(

              <Button className="subscription-upgrade" onClick={openSubsciptionModal}>Upgrade</Button>
              )}
            </div>
            <ProfileOtherDetailsCard data={bankDetails} />
            <ProfileOtherDetailsCard data={beneficiaryDetails} />
            <ProfileOtherDetailsCard data={emergencyDetails} />
          </Col>
        </Row>
        <Row
          className={`customer-profile__body-kyc-details ${disableActionEvents && 'pointer-none'}`}
          justify="space-between"
        >
          <Col span={24}>
            <div className="kyc-document-details__header">
              <div className="kyc-document-details--title">
                <div className="title-icon">
                  <img src={DocumentIcon} alt="emergency" />
                </div>
                Documents
              </div>
            </div>
          </Col>
          {kycDetails?.kycIdProof?.photograph && (
            <Col lg={7} sm={24} xs={24}>
              <DocumentViewer
                refreshData={() => customerId && fetchData(customerId)}
                name="Photograph"
                customerId={customer?.id}
                keyName="photograph"
                hideStatus={enableVerification}
                document={kycDetails?.kycIdProof?.photograph}
                status={
                  verificationData.photograph === undefined
                    ? "pending"
                    : verificationData.photograph === false
                      ? "accepted"
                      : "rejected"
                }
                onClick={() => {
                  setSelectedDoc({
                    url: kycDetails?.kycIdProof?.photograph || "",
                    type: "pdf",
                    title: "photograph",
                    name: "Photograph",
                    visible: true,
                    status: statusUpdater(
                      "photograph",
                      kycDetails?.kycIdProof?.photographVerifiedBy,
                      kycDetails?.kycIdProof?.photographRejectionReason
                    ),
                  });
                }}
              />
            </Col>
          )}
          {kycDetails?.kycIdProof?.kenyanIdProof && (
            <Col lg={7} sm={24} xs={24}>
              <DocumentViewer
                refreshData={() => customerId && fetchData(customerId)}
                keyName="kenyanIdProof"
                customerId={customer?.id}
                name="Kenyan ID Proof"
                document={kycDetails?.kycIdProof?.kenyanIdProof}
                hideStatus={!enableVerification}
                // enableUpload={enableVerification && verificationData.kenyanNationalId === false}
                status={
                  verificationData.kenyanNationalId === undefined
                    ? "pending"
                    : verificationData.kenyanNationalId === false
                      ? "accepted"
                      : "rejected"
                }
                onClick={() => {
                  setSelectedDoc({
                    url: kycDetails?.kycIdProof?.kenyanIdProof || "",
                    name: "Kenyan ID Proof",
                    type: "pdf",
                    title: "kenyanNationalId",
                    visible: true,
                    status: statusUpdater(
                      "kenyanNationalId",
                      kycDetails?.kycIdProof?.kenyanIdProofVerifiedBy,
                      kycDetails?.kycIdProof?.kenyanIdProofRejectionReason
                    ),
                  });
                }}
              />
            </Col>
          )}
          {kycDetails?.kycIdProof?.kraPin && (
            <Col lg={7} sm={24} xs={24}>
              <DocumentViewer
                refreshData={() => customerId && fetchData(customerId)}
                keyName="kraPin"
                customerId={customer?.id}
                name="KRA Pin"
                document={kycDetails?.kycIdProof?.kraPin}
                // enableUpload={!enableVerification && verificationData.kraPin === false}
                hideStatus={enableVerification}
                status={
                  verificationData.kraPin === undefined
                    ? "pending"
                    : verificationData.kraPin === false
                      ? "accepted"
                      : "rejected"
                }
                onClick={() => {
                  setSelectedDoc({
                    url: kycDetails?.kycIdProof?.kraPin || "",
                    type: "pdf",
                    name: "KRA Pin",
                    title: "kraPin",
                    visible: true,
                    status: statusUpdater(
                      "kraPin",
                      kycDetails?.kycIdProof?.kraPinVerifiedBy,
                      kycDetails?.kycIdProof?.kraPinRejectionReason
                    ),
                  });
                }}
              />
            </Col>
          )}
        </Row>
        {kycDetails?.kycChamaUsers?.map((user, i) => <Row
          className={`customer-profile__body-chama-kyc-details ${disableActionEvents && 'pointer-none'}`}
          gutter={[16, 24]}
        >
          <Col span={24}>
            <div className="kyc-document-details__header">
              <div className="kyc-document-details--title">
                <div className="title-icon">
                  <img src={BeneficiaryIcon} alt="emergency" />
                </div>
                Chama Person {i + 2} details
              </div>
            </div>
          </Col>
          <Col span={24}>
            <Row gutter={[32, 16]}>
              <Col>
                <p className="subtitle">Name</p>
                <p className="value">{user?.firstName || ''} {user?.middleName || ''} {user?.lastName || ''}</p>
              </Col>
              <Col>
                <p className="subtitle">Kenyan National ID/Alien Card Number / Passport</p>
                <p className="value">{user?.kenyanNationalNumber || ''}</p>
              </Col>
              <Col>
                <p className="subtitle">KRA Pin number</p>
                <p className="value">{user?.kraPinNumber || ''}</p>
              </Col>
            </Row>
          </Col>
          {user?.photograph && (
            <Col lg={7} sm={24} xs={24}>
              <DocumentViewer
                refreshData={() => customerId && fetchData(customerId)}
                name="Photograph"
                hideStatus={false}
                // enableUpload={false}
                document={user?.photograph}
                status={""}
                onClick={() => {
                  setSelectedDoc({
                    url: user?.photograph || "",
                    type: "pdf",
                    title: "photograph",
                    name: "Photograph",
                    visible: true,
                    status: "",
                  });
                }}
              />
            </Col>
          )}
          {user?.kenyanIdProof && (
            <Col lg={7} sm={24} xs={24}>
              <DocumentViewer
                refreshData={() => customerId && fetchData(customerId)}
                name="Kenyan ID Proof"
                document={user?.kenyanIdProof}
                hideStatus
                // enableUpload={false}
                status={""}
                onClick={() => {
                  setSelectedDoc({
                    url: user?.kenyanIdProof || "",
                    name: "Kenyan ID Proof",
                    type: "pdf",
                    title: "kenyanNationalId",
                    visible: true,
                    status: "",
                  });
                }}
              />
            </Col>
          )}
        </Row>)}
        <Row className={`customer-profile__body-risk-assessment ${disableActionEvents && 'pointer-none'}`}>
          <Col span={24}>
            <p className="customer-profile__body-risk-assessment--title">
              Risk Assessment Questions
            </p>
          </Col>
          <Col span={24}>
            <RiskAssessmentQuestion
              questions={kycDetails?.kycRiskAssesment || []}
            />
          </Col>
        </Row>
      </Fragment>
    );
  };

  return (
    <div className="customer-profile__container">
      <div className="customer-profile__header">
        <PageHeader
          title={`${customer?.kycBasicInfo?.firstName?.toLowerCase() || ''} ${customer?.kycBasicInfo?.lastName?.toLowerCase() || ''} ${customer?.kycBasicInfo?.middleName?.toLowerCase() || ''}`}
          backText="Customers List"
          headerActions={
            enableVerification && !!customer ? (
              <div className="customer-profile--save">
                <Button
                  type="primary"
                  disabled={Object.values(verificationData).some(
                    (ele) => ele === undefined
                  )}
                  onClick={saveChangesHandler}
                  loading={approving}
                >
                  Save Changes
                </Button>
                <p>
                  Verify Video KYC (
                  {verificationData.video === undefined ? 0 : 1}/1) and
                  documents (
                  {0 +
                    (verificationData.photograph === undefined ? 0 : 1) +
                    (verificationData.kraPin === undefined ? 0 : 1) +
                    (verificationData.kenyanNationalId === undefined ? 0 : 1)}
                  /3) to approve customer
                </p>
              </div>
            ) : undefined
          }
        />
      </div>
      {!!customer && (
        <Tabs defaultActiveKey="1" activeKey={selectedTab} onChange={tabChangeHandler}>
          <TabPane tab="Details" key="1">
            <div className="customer-profile__body">
              {tabContentRenderer(customer || {})}
            </div>
          </TabPane>
          {!enableVerification && (
            <Fragment>
              <TabPane tab="Holdings" key="2">
                <CustomerHoldings />
              </TabPane>
              <TabPane tab="Wallet" key="3">
                <CustomerWallet customer={customer} changeTab={tabChangeHandler} />
              </TabPane>
            </Fragment>
          )}
        </Tabs>
      )}
      <div className={`${disableActionEvents && 'pointer-none'}`}>
      <div className="customer-profile__body"></div>
      <KycDocumentModal
        hideStatus={enableVerification}
        name={selectedDoc.name}
        customer={customer || {}}
        kycDetails={kycDetails || {}}
        visible={selectedDoc.visible}
        type={selectedDoc.type}
        url={selectedDoc.url}
        title={selectedDoc.title}
        status={selectedDoc.status || ""}
        updateVerificationStatus={statusUpdateHandler}
        closeHandler={documentModalCloseHandler}
      />
      <AlertModal
        title="Lookup ID"
        okText="Confirm & Proceed"
        visible={changeDetails}
        onOk={handleConfirm}
        onCancel={() => updateChangeDetails(false)}
        confirmLoading={loading}
      >
        {ChangeDetailsTemplate}
      </AlertModal>
      <CustomerDetails customer={reviewDetails?.customer || {}}
        visible={!!reviewDetails}
        onOk={handleSyncDetails}
        onCancel={() => setReviewDetails(undefined)} />
      {customerId && <SubscriptionModal
        visible={subscriptionModal}
        customerId={customerId}
        closeHandler={closeSubsciptionModal} />}
      </div>
    </div>
  );
};

export default AuthContainer(CustomerProfile);
