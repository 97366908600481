import { Button, Tabs, Tooltip, Switch } from "antd";
import React, { Fragment, useState } from "react";
import PageHeader from "../../../../../shared/components/PageHeader";
import "./customerHoldings.scss";
import TableComponent from "../../../../../shared/components/TableComponent";
// import ShareService from "../../../services/SharesService/share.service";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import AuthService from "../../../../../services/AuthService/auth.service";
import { numberConvention } from "../../../../../shared/utils/numberConvention";
import { AuthState } from "../../../../../store/reducers/authReducer";
import AuthContainer from "../../../../../store/container/AuthContainer";
import * as AppRoutes from "../../../../../routes/routeConstants/appRoutes";
import WalletService from "../../../../../services/WalletService/wallet.service";
import ButtonComponent from "../../../../../shared/components/ButtonComponent";
import CustomerService from "../../../../../services/CustomerService/customer.service";
import StyledModalPlaceOrder from "../../../../../shared/components/StyledModalPlaceOrder";
import { CustomerTypes } from "../../../../../enums/customerTypes";


const buyColumns = [
  {
    title: "Date",
    dataIndex: "orderDate",
    key: "orderDate",
  },
  {
    title: "Units",
    dataIndex: "units",
    key: "units",
  },
  {
    title: "Unit Cost",
    dataIndex: "unitPrice",
    key: "unitPrice",
  },
  {
    title: "Order Value",
    dataIndex: "orderValue",
    key: "orderValue",
  },
  {
    title: "Status",
    dataIndex: "orderStatus",
    key: "orderStatus",
    render: (text: string) => <span className={text}>{text}</span>,
  },
];

const sellColumns = [
  {
    title: "Date",
    dataIndex: "orderDate",
    key: "orderDate",
  },
  {
    title: "Units",
    dataIndex: "units",
    key: "units",
  },
  {
    title: "Unit Cost",
    dataIndex: "unitPrice",
    key: "unitPrice",
  },
  {
    title: "Order Value",
    dataIndex: "orderValue",
    key: "orderValue",
  },
  {
    title: "Status",
    dataIndex: "orderStatus",
    key: "orderStatus",
    render: (text: string) => <span className={text}>{text}</span>,
  },
];

interface HoldingsProps extends AuthState { }

const Holdings = (props: HoldingsProps) => {
  const { TabPane } = Tabs;
  const history = useHistory();
  const customerId = history.location.pathname.split("/").pop();
  const { user } = props;

  const [buyMode, setBuyMode] = useState<boolean>();
  const [selectedTab, setSelectedTab] = useState("0");

  // const { getBuySharesHistory, getSellSharesHistory, sharesHistory, loading } = ShareService()
  const { getUserDetails } = AuthService();

  const { getWalletDetails, wallet } = WalletService();
  const { fetchCustomerBuySellOrder, buySellDetail, loading,fetchSingleCustomerBasicDetails, customer } = CustomerService(); 
  const [shareModal, setShareModal] = useState<{
    visibility: boolean;
    data: string;
  }>({ visibility: false, data: "" });

  const disableActionEvents: boolean = user?.role === CustomerTypes.DIGITAL;

  useEffect(() => {
    const state = history.location.state as { buyMode: boolean | undefined };
    setBuyMode(state?.buyMode !== undefined ? state?.buyMode : true);
    if (customerId) {
      getWalletDetails(customerId);
      fetchSingleCustomerBasicDetails(customerId)
    }
  }, []);

  useEffect(() => {
    customerId && fetchCustomerBuySellOrder(customerId, { is_sell_order: selectedTab });
  }, [selectedTab]);

  useEffect(() => {
    if (buyMode === undefined) {
      return;
    }
    /*   if (customerId) fetchCustomerBuySellOrder(custo merId); */
    // if (buyMode) {
    //     getBuySharesHistory()
    // } else {
    //     getSellSharesHistory()
    // }
  }, [buyMode]);

  const tabButtons = (
    <div className="holdings-tab--actions">
      {/* <ButtonComponent className="buy" type="default">
        Buy Shares
      </ButtonComponent>
      <ButtonComponent className="sell" type="default">
        Sell Shares
      </ButtonComponent> */}
    </div>
  );

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    customerId && fetchCustomerBuySellOrder(customerId, {
      is_sell_order: selectedTab,
      page: pagination.current
    });

    /* fetchCustomers(filterSearch); */
  }; 

  const handleOpenShareModal = (isBuy: boolean) => {
    setBuyMode(isBuy);
    setShareModal({
      visibility: true,
      data: isBuy ? "buy" : "sell",
    });
  };

  const ordersTabs = (
    <Tabs
      className="holdings-tab"
      tabBarExtraContent={tabButtons}
      defaultActiveKey="0"
      onChange={setSelectedTab}
    >
      <TabPane tab="Buy Orders" key="0">
        <div className={`${disableActionEvents && 'pointer-none'}`}>
          <div className="holdings__auto-dividend--container">
            <h3 className="holdings__auto-dividend--heading">
              Auto Invest Dividend
            </h3>
            <Switch
              checked={customer?.autoDividendInvestOption === 1}
              disabled
            />
          </div>
          <div className="holdings__buy-shares--container">
            <Button
              type="ghost"
              size="middle"
              className="holdings__buy-shares--btn"
              onClick={() => handleOpenShareModal(true)}
              disabled={!customer?.activeSubscription || user?.role===CustomerTypes.DIGITAL}
              title={!customer?.activeSubscription ? "No active subscription" : ""}
            >
              {"Buy Units"}
            </Button>
          </div>

          <div className="holdings__sell-shares--container">
            <Button
              type="ghost"
              size="middle"
              className="holdings__sell-shares--btn"
              onClick={() => handleOpenShareModal(false)}
              disabled={!customer?.activeSubscription || user?.role===CustomerTypes.DIGITAL}
              title={!customer?.activeSubscription ? "No active subscription" : ""}
            >
              {"Sell Units"}
            </Button>
          </div>
          <StyledModalPlaceOrder
            visible={shareModal.visibility}
            user={user}
            wallet={wallet}
            buyMode={buyMode}
            title={shareModal.data === "buy" ? "Buy Units" : "Sell Units"}
            className={shareModal.data === "buy" ? "yellow-bg" : "blue-bg"}
            dynamicWalletBalance={wallet?.dynamicBalance || 0}
            staticWalletBalance={wallet?.staticBalance || 0}
            okText={
              shareModal.data === "buy"
                ? "Add 8,000 to Wallet & Buy Units"
                : "Confirm Sell Units"
            }
            onCancel={() => {
              setShareModal((prev) => {
                return {
                  ...prev,
                  visibility: false,
                };
              });
            }}
          />
        <TableComponent
          loading={loading}
          className="holdings-table"
          columns={buyColumns}
          rowKey="id"
          dataSource={buySellDetail?.data || []}
          pagination={{
            pageSize: buySellDetail?.perPage || 0,
            total: buySellDetail?.total || 0,
            current: buySellDetail?.currentPage || 0,
            showTotal: (total: number, range: [number, number]) => (
              <p>
                Showing <b>{` ${range[0]} - ${range[1]}`}</b> of{" "}
                <b>{`${total.toLocaleString()}`}</b>
              </p>
            ),
          }}
          onChange={handleTableChange}
        />
        </div>
      </TabPane>
      <TabPane tab="Sell Orders" key="1">
        <div className={`${disableActionEvents && 'pointer-none'}`}>
        <TableComponent
          loading={loading}
          className="holdings-table"
          columns={sellColumns}
          rowKey="id"
          dataSource={buySellDetail?.data || []}
          pagination={{
            pageSize: buySellDetail?.perPage || 0,
            total: buySellDetail?.total || 0,
            current: buySellDetail?.currentPage || 0,
            showTotal: (total: number, range: [number, number]) => (
              <p>
                Showing <b>{` ${range[0]} - ${range[1]}`}</b> of{" "}
                <b>{`${total.toLocaleString()}`}</b>
              </p>
            ),
          }}
          onChange={handleTableChange}
        />
        </div>
      </TabPane>
    </Tabs>
  );

  return (
    <Fragment>
      <div className="holdings__container">
        <div className="holdings">
          <div className="holdings-data">
            <div className="holdings-data--header-title">Total Investment</div>
            <div className="holdings-data--header-value">
              {wallet?.totalInvestment} KES
            </div>
          </div>
          <div className="holdings-data">
            <div className="holdings-data--header-title">Total Units Held</div>
            <div className="holdings-data--header-value">
              {wallet?.holdings || 0}
            </div>
          </div>
          <div className="holdings-data">
            <div className="holdings-data--header-title">Dividends Earned</div>
            <div className="holdings-data--header-value">
              {wallet?.dividendsEarned} KES
            </div>
          </div>
          <div className="holdings-data">
            <div className="holdings-data--header-title">
              Capital Appreciation
            </div>
            <div className="holdings-data--header-value">
              {wallet?.capitalAppreciation} KES
            </div>
          </div>
          <div className="holdings-data">
            <div className="holdings-data--header-title">Current Valuation</div>
            <div className="holdings-data--header-value">
              {wallet?.currentShareValue} KES
            </div>
          </div>
          {/* <div className="holdings-data">
                    <div className="holdings-data--header-title">Total Investment</div>
                    <div className="holdings-data--header-value">0 KES</div>
                </div> */}
          <div className="holdings-data">
            <div className="holdings-data--header-title">% Returns</div>
            <div className="holdings-data--header-value">
              {wallet?.returnsPercent}%
            </div>
          </div>
        </div>
        <div className="holdings__data">{ordersTabs}</div>
      </div>
    </Fragment>
  );
};

export default AuthContainer(Holdings);
