import { serializable, alias, object, list, primitive } from "serializr";
import { PaginationWrapper } from "../PaginationWrapper/paginationWrapper.model";

export class CustomerBuySellOrder {
  @serializable(alias("order_date", primitive()))
  orderDate?: string;
  @serializable(alias("units", primitive()))
  units?: number;
  @serializable(alias("unit_price", primitive()))
  unitPrice?: number;
  @serializable(alias("order_value", primitive()))
  orderValue?: number;
  @serializable(alias("order_status", primitive()))
  orderStatus?: string;
  @serializable(alias("status_color", primitive()))
  statusColor?: string;
}

export class CustomerBuySellOrders extends PaginationWrapper {
  @serializable(alias("data", list(object(CustomerBuySellOrder))))
  data?: CustomerBuySellOrder[];
}

export class Order {
  @serializable(alias("dial_code", primitive()))
  dialCode?: string;

  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("order_date", primitive()))
  orderDate?: string;

  @serializable(alias("order_status", primitive()))
  orderStatus?: string;

  @serializable(alias("phone", primitive()))
  phone?: string;

  @serializable(alias("quantity", primitive()))
  quantity?: number;

  @serializable(alias("role", primitive()))
  role?: string;

  @serializable(alias("subscription", primitive()))
  subscription?: string;
}
